import { eachComponent } from '@platform/formio/utils/formUtils';
/**
 * Функция извлекает из данных формы "Property Name" элементов
 * @param components Массив компонент формы (обычно приходит с бэка как formInfo.form.components)
 * @param typesToMatch Массив типов компонент (например, ['categoryFile', 'textarea']), для которых необходимо извлечь "Property Name"
 * @param includeAll Если true, то происходит итераиция и по layout-компонентам
 * ("Property Name" в компоненте хранится как component.key)
 */
export var getComponentsKeysByType = function (components, typesToMatch, includeAll) {
    if (includeAll === void 0) { includeAll = false; }
    var accumulator = [];
    eachComponent(components, function (component) {
        var type = component.type, key = component.key;
        if (key && type) {
            if (typesToMatch.includes(type)) {
                accumulator.push(key);
            }
        }
    }, includeAll);
    return accumulator;
};
export var mapFileDataToFileDTO = function (fileData, lang) {
    var _a;
    return {
        id: (_a = fileData === null || fileData === void 0 ? void 0 : fileData.data) === null || _a === void 0 ? void 0 : _a.id,
        lang: lang,
        category: fileData === null || fileData === void 0 ? void 0 : fileData.category,
    };
};
export var extractFilesDataFromCategoryFileDataArray = function (categoryFileData, lang, accumulator) {
    for (var i = 0; i < categoryFileData.length; i++) {
        var fileData = categoryFileData[i];
        accumulator.push(mapFileDataToFileDTO(fileData, lang));
    }
};
/**
 * Функция итерируется по массиву внутри сабмишена
 */
export var extractFilesDataFromArray = function (data, lang, accumulator, keysToMatch) {
    for (var i = 0; i < data.length; i++) {
        var element = data[i];
        if (typeof element === 'object' && !Array.isArray(element) && element !== null) {
            extractFilesDataFromObject(element, lang, accumulator, keysToMatch);
        }
    }
};
/**
 * Функция итерируется по объекту внутри сабмишена
 */
export var extractFilesDataFromObject = function (data, lang, accumulator, keysToMatch) {
    var dataObjectKeys = Object.keys(data);
    for (var i = 0; i < dataObjectKeys.length; i++) {
        var key = dataObjectKeys[i];
        var valueByKey = data[key];
        if (keysToMatch.includes(key)) {
            extractFilesDataFromCategoryFileDataArray(valueByKey, lang, accumulator);
        }
        else if (Array.isArray(valueByKey)) {
            extractFilesDataFromArray(valueByKey, lang, accumulator, keysToMatch);
        }
        else if (typeof valueByKey === 'object' && valueByKey !== null) {
            extractFilesDataFromObject(valueByKey, lang, accumulator, keysToMatch);
        }
    }
};
/**
 * Функция извлекает из сабмишена данные файлов
 * @param submission Сабмишен
 * @param keysToMatch Массив строк, где каждая строка - "Property Name" компоненты, у которой component.type === 'categoryFile'
 * ("Property Name" в компоненте хранится как component.key)
 */
export var getFilesDataFromSubmission = function (submission, keysToMatch) {
    var accumulator = [];
    var locales = Object.keys(submission);
    for (var i = 0; i < locales.length; i++) {
        var locale = locales[i];
        if (!submission[locale].data) {
            continue;
        }
        extractFilesDataFromObject(submission[locale].data, locale, accumulator, keysToMatch);
    }
    return accumulator;
};
